@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Custom Styles for Preloader Animation */
@keyframes slideUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Preloader Animation */
.preloader {
  animation: slideUp 1s ease-out forwards;
}

/* Loading Triangles Animation */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-4px);
  }
}

.loading-triangle {
  animation: bounce 1s infinite ease-in-out;
}

.loading-triangle:nth-child(2) {
  animation-delay: 0.2s; /* Delay the bounce animation for the second triangle */
}

.loading-triangle:nth-child(3) {
  animation-delay: 0.4s; /* Delay the bounce animation for the third triangle */
}

body.modal-open {
  overflow: hidden !important;
}

/* Datepicker */
.react-datepicker {
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.react-datepicker__header {
  background-color: #f7fafc; /* Tailwind color: bg-gray-100 */
  border-bottom: 1px solid #e2e8f0; /* Tailwind color: border-gray-300 */
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500; /* Tailwind font-weight: font-medium */
  color: #1a202c; /* Tailwind color: text-gray-900 */
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #4a5568; /* Tailwind color: text-gray-700 */
  margin: 0.2rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected:hover {
  background-color: #3182ce; /* Tailwind color: bg-blue-600 */
  color: white;
  border-radius: 0.5rem;
}

.react-datepicker__day:hover {
  background-color: #ebf8ff; /* Tailwind color: bg-blue-100 */
  border-radius: 0.5rem;
}
